import Dropdown from "@sb1/ffe-dropdown-react";
import { Input, InputGroup, RadioButtonInputGroup, RadioSwitch, Checkbox } from "@sb1/ffe-form-react";
import { Tab, TabGroup } from "@sb1/ffe-tabs-react";
import React, { useEffect, useState } from "react";
import ContentCard from "../../../../Shared/ContentCard/ContentCard";
import { Loanapplication as LoanapplicationModel} from "../../../../../model/Loanapplication";
import { toast } from 'react-toastify';
import Select from 'react-select';

import styles from './CaseInsuranceCard.module.scss';
import { ActionButton } from "@sb1/ffe-buttons-react";
import { useAxios } from "../../../../../AxiosHandler";
import { useAuth } from "../../../../../Auth";
import general from "../../../../../functions/general";
import { useLoanapplication } from "../../../../../LoanapplicationContext";

function CaseInsuranceCard(props: CaseInsuranceCardProps) {
    const auth = useAuth();
    const loanapplication = useLoanapplication();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;

    const [insuranceBonusOptions, setInsuranceBonusOptions] = useState<any>([]);
    const [insuranceAnnualKilometersOptions, setInsuranceAnnualKilometersOptions] = useState<any>([]);
    const [insuranceCompanies, setInsuranceCompanies] = useState<any>([]);
    const [insuranceMortgageesOptions, setInsuranceMortgageesOptions] = useState<any>([]);

    const [calculationResult, setCalculationResult] = useState<any>(undefined);
    const [calculating, setCalculating] = useState<boolean>(false);
    const [selectedInsurance, setSelectedInsurance] = useState<any>(undefined);
    const [selectedDeductible, setSelectedDeductible] = useState<any>(undefined);

    const [problems, setProblems] = useState<Array<string>>([]);

    const [tooltipOpen, setTooltipOpen] = useState<any>(undefined);

    function getProduktType(makeSequence: number | undefined){
        switch(makeSequence){
            case 1:
                return "BIL";
            case 6:
                return "BOBIL";
            case 7:
                return "CAMPINGVOGN";
            default:
                return "OTHER";
        }
    }

    function calculatePrice() {
        setCalculating(true);
        setCalculationResult(undefined);
        setSelectedInsurance(undefined);
        setSelectedDeductible(undefined);
        toast.info("Prisberegning pågår ...", { autoClose: false });
        axios.single({
            url: "/api/fremtind/bil/prisberegning",
            method: "POST",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"]),
                "X-Request-Id": general.generateXRequestId(),
            },
            data: {
                "regnr": props.currentLoanapplication?.regnumber != "" ? props.currentLoanapplication?.regnumber : undefined,
                "merkenr": props.currentLoanapplication?.regnumber == undefined || props.currentLoanapplication?.regnumber == "" ? props.currentLoanapplication?.objectmake : undefined,
                "modellnr": props.currentLoanapplication?.regnumber == undefined || props.currentLoanapplication?.regnumber == "" ? props.currentLoanapplication?.objectmodel : undefined,
                "variantnr": props.currentLoanapplication?.regnumber == undefined || props.currentLoanapplication?.regnumber == "" ? props.currentLoanapplication?.objecttype : undefined,
                "aarsModell": props.currentLoanapplication?.regnumber == undefined || props.currentLoanapplication?.regnumber == "" ? props.currentLoanapplication?.objectyear : undefined,
                "belaanesMedPant": true,
                "bonus": props.currentLoanapplication?.insuranceBonus,
                "eierFodselsnummer": props.currentLoanapplication?.customerSocialSecurityNumber,
                "kilometerstand": props.currentLoanapplication?.mileage,
                "kjorelengdeAar": props.currentLoanapplication?.insuranceAnnualKilometers,
                "leasing": props.currentLoanapplication?.type?.toLowerCase().includes("leasing"),
                "markedsKilde": "625",
                "produkt": getProduktType(props.objectCategorySequence), // TODO: How do we map this from props.currentLoanapplication?.category ?
                "yngreSjaafor": props.currentLoanapplication?.insuranceLowAge,
                "tilleggsDekninger": []
            }
        }).then((res: any) => {
            setCalculating(false);
            setCalculationResult(res.data?.dekningsPriser);
            toast.dismiss();
            if(res.data.error){
                toast.error("Fremtind: " + res.data.error.errorMessage);
            }
        }).catch((err: any) => {
            setCalculating(false);
            console.log(err);
            toast.dismiss();
            toast.error("Prisberegning feilet! " + err?.response?.data);
        });
    }

    function fetchMortgagees() {
        axios.single({
            url: "/api/fremtind/kodeverk/panthavere",
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setInsuranceMortgageesOptions(res.data?.selskap?.map((x: any) => {
                return {
                    value: x.kundenummer,
                    label: x.navn
                }
            }));
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av panthavere feilet!");
        });
    }

    function fetchInsuranceCompanies() {
        axios.single({
            url: "/api/company/insurancecompanies",
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setInsuranceCompanies(res.data?.map((x: any) => {
                return {
                    value: x.Sequence,
                    label: x.CompanyName
                }
            }));
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av forsikringsselskaper feilet!");
        });
    }

    function fetchInsuranceAnnualKilometers() {
        axios.single({
            url: "/api/fremtind/kodeverk/kjorelengder",
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            if (res.data.kilometerLevels != undefined) {
                setInsuranceAnnualKilometersOptions(res.data.kilometerLevels.sort((x: any, y: any) => Number(x) > Number(y) ? 0 : -1)?.map((x: any) => {
                    return {
                        value: x,
                        label: x + " 000"
                    }
                }));
            } else {
                toast.error("Innlasting av kjørelengder feilet!");
                if (res.data.error != undefined) {
                    toast.error("Fremtind | " + res.data.error.errorCode + ": " + res.data.error.errorMessage);
                }
            }
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av kjørelengder feilet!");
        });
    }

    function fetchInsuranceBonus() {
        axios.single({
            url: "/api/fremtind/kodeverk/bonus",
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            if (res.data.bonusLevels != undefined) {
                setInsuranceBonusOptions(res.data.bonusLevels.filter((x: any) => x.isActive)?.map((x: any) => {
                    return {
                        value: x.key,
                        label: x.caption
                    }
                }));
            } else {
                toast.error("Innlasting av bonus feilet!");
                if (res.data.error != undefined) {
                    toast.error("Fremtind | " + res.data.error.errorCode + ": " + res.data.error.errorMessage);
                }
            }
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av bonus feilet!");
        });
    }

    function getTooltipText(dekningsTypeKode: string) {
        let tooltipText = "";
        let item = calculationResult?.find((x: any) => x.dekningsTypeKode == dekningsTypeKode);
        if (item != undefined) {
            tooltipText = item.beskrivelse;
        }
        return tooltipText;
    }

    function insuranceEnabled(){
        let agreement = general.getAgreement(auth.selectedDealer, props.currentLoanapplication)?.agreement;
        let segment = props.currentLoanapplication?.segment;

        return auth.selectedDealer?.insuranceEnabled === true && 
            (
                (segment === "Privat" && agreement?.insurance?.insuranceEnabledPM == true) || 
                (segment === "Bedrift" && agreement?.insurance?.insuranceEnabledBM == true)
            );
    }

    useEffect(() => {
        setCalculationResult(undefined);
        setSelectedInsurance(undefined);
        setSelectedDeductible(undefined);
    }, [
        props.currentLoanapplication?.objectmake, 
        props.currentLoanapplication?.objecttype, 
        props.currentLoanapplication?.objectmodel,
        props.currentLoanapplication?.variant,
        props.currentLoanapplication?.calculationResult,
        props.currentLoanapplication?.customerSocialSecurityNumber,
        props.currentLoanapplication?.agreementId
    ])

    useEffect(() => {
        let tempProblems: Array<string> = [];

        //props.objectCategorySequence 7 = CAMPINGVOGN
        if (props.objectCategorySequence != 7 && (props.currentLoanapplication?.regnumber == undefined || props.currentLoanapplication?.regnumber == "")) {
            if (
                props.currentLoanapplication?.objectmake == undefined || 
                props.currentLoanapplication?.objectmodel == undefined ||
                props.currentLoanapplication?.objecttype == undefined
            ) tempProblems.push("Registreringsnummer eller merke, modell og variant må fylles ut");
        }

        if (props.currentLoanapplication?.customerSocialSecurityNumber == undefined || props.currentLoanapplication?.customerSocialSecurityNumber == "") tempProblems.push("Kunde må angis med personnummer");

        if (props.objectCategorySequence != 7 && props.currentLoanapplication?.insuranceAnnualKilometers == undefined) tempProblems.push("Ønsket kjørelengde må fylles ut");
        if (props.objectCategorySequence != 7 && props.currentLoanapplication?.insuranceBonus == undefined) tempProblems.push("Nåværende bonus må fylles ut");
        if (props.objectCategorySequence != 7 && props.currentLoanapplication?.insuranceLowAge == undefined) tempProblems.push("Yngre sjåfør må fylles ut");

        if (props.objectCategorySequence == 7){
            if(props.currentLoanapplication?.objectmakeCode == undefined)
                tempProblems.push("Objektmerke må fylles ut");
            
            if (
                (props.currentLoanapplication?.regnumber == undefined ||
                    props.currentLoanapplication?.regnumber == "") && 
                (props.currentLoanapplication?.chassisnumber == undefined ||
                    props.currentLoanapplication?.chassisnumber == "")
            )
                tempProblems.push("Objektet må ha registreringsnummer eller chassisnummer");

            if(!props.currentLoanapplication?.oneMonthFreeInsurance)
                tempProblems.push("Det må bekreftes at kunden ønsker 1 måned gratis forsikring");
        }

        setProblems(tempProblems);
    }, [
        props.currentLoanapplication?.insuranceAnnualKilometers,
        props.currentLoanapplication?.insuranceBonus,
        props.currentLoanapplication?.insuranceLowAge,
        props.currentLoanapplication?.objectmake,
        props.currentLoanapplication?.objectmakeCode,
        props.currentLoanapplication?.objectmodel,
        props.currentLoanapplication?.objecttype,
        props.currentLoanapplication?.regnumber,
        props.currentLoanapplication?.customerSocialSecurityNumber,
        props.currentLoanapplication?.oneMonthFreeInsurance,
        props.objectCategorySequence
    ]);

    useEffect(() => {
        fetchInsuranceAnnualKilometers();
        fetchInsuranceBonus();
        fetchInsuranceCompanies();
        fetchMortgagees();
    }, []);

    useEffect(() => {        
        if(
            auth.selectedDealer?.insuranceEnabled != true || 
            props.currentLoanapplication?.dealerGroupAgreementConnection?.insurance?.insuranceEnabled != true || 
            getProduktType(props.objectCategorySequence) == "OTHER"
        ){
            loanapplication.setRequestingInsurance(false);
            loanapplication.setInsuranceAnnualKilometers(undefined);
            loanapplication.setInsuranceBonus(undefined);
            loanapplication.setInsuranceLowAge(false);
            loanapplication.setLoanLeasingCompany(undefined);
        }
    }, [auth.selectedDealer?.insuranceEnabled, props.currentLoanapplication?.dealerGroupAgreementConnection?.insurance?.insuranceEnabled]);

    return(
        <ContentCard disabled={props.disabled} open={props.open} title="Forsikring" className={styles.caseinsurancecard}>
            <TabGroup className={styles.insurancetoggle}>
                {insuranceEnabled() != true || 
                getProduktType(props.objectCategorySequence) == "OTHER" ?
                    null 
                :
                    getProduktType(props.objectCategorySequence) == "CAMPINGVOGN" ? 
                        <Tab
                            selected={props.currentLoanapplication?.requestingInsurance != undefined ? props.currentLoanapplication?.requestingInsurance : false}
                            onClick={() => {
                                loanapplication.setRequestingInsurance(true);
                            }}
                            aria-controls={"getinsurance"}
                        >
                            Gratis 1 måned
                        </Tab>
                    :
                        <Tab
                            selected={props.currentLoanapplication?.requestingInsurance != undefined ? props.currentLoanapplication?.requestingInsurance : false}
                            onClick={() => {
                                loanapplication.setRequestingInsurance(true);
                            }}
                            aria-controls={"getinsurance"}
                        >
                            Ønsker forsikring
                        </Tab>
                }
                <Tab
                    selected={props.currentLoanapplication?.requestingInsurance != undefined ? !props.currentLoanapplication?.requestingInsurance : false}
                    onClick={() => {
                        loanapplication.setRequestingInsurance(false);
                    }}
                    aria-controls={"hasinsurance"}
                >
                    Har forsikring
                </Tab>
            </TabGroup>
            { props.currentLoanapplication?.requestingInsurance != undefined && props.currentLoanapplication?.requestingInsurance == false ?
                <>
                    <InputGroup
                        label="Objekt forsikres i"
                        tooltip="Fritekst søk etter forsikringsselskapet kunden har valgt"
                        //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                    >
                        <Select
                            noOptionsMessage={() => "Fant ingen forsinkringsselskap ..."}
                            placeholder="Søk ..."
                            loadingMessage={() => "Laster inn forsinkringsselskap ..."}
                            className="basic-singleselect"
                            classNamePrefix="select"
                            value={
                                [
                                    {
                                        value: props.currentLoanapplication?.insuranceCompany,
                                        label: insuranceCompanies?.find((i: any) => i.value == props.currentLoanapplication?.insuranceCompany)?.label,
                                    }
                                ]
                            }
                            defaultValue={
                                [
                                    {
                                        value: props.currentLoanapplication?.insuranceCompany,
                                        label: insuranceCompanies?.find((i: any) => i.value == props.currentLoanapplication?.insuranceCompany)?.label,
                                    }
                                ]
                            }
                            isClearable={true}
                            isSearchable={true}
                            options={insuranceCompanies}
                            onInputChange={(e) => {
                                if (insuranceCompanies.some((i: any) => i.label == e)) {
                                    loanapplication.setInsuranceCompany(insuranceCompanies.find((i: any) => i.label == e)?.value);
                                }
                            }}
                            onChange={(e) => {
                                loanapplication.setInsuranceCompany((e as any)?.value);
                            }}
                        />
                    </InputGroup>
                    &nbsp;
                </>
            : null }
            { props.currentLoanapplication?.requestingInsurance != undefined && props.currentLoanapplication?.requestingInsurance == true ?
                getProduktType(props.objectCategorySequence) == "CAMPINGVOGN" ? 
                    <>
                        <InputGroup
                            className={styles.checkboxinputgroup}
                        >
                            <div className={styles.checkboxcontainer}>
                                <Checkbox
                                    name="oneMonthFreeInsurance"
                                    inline={false}
                                    checked={loanapplication.oneMonthFreeInsurance}
                                    onChange={e => {
                                        loanapplication.setOneMonthFreeInsurance(e.target.checked);
                                    }}
                                >
                                    Ønsker kunden 1 mnd gratis forsikring fra Sparebank1?
                                </Checkbox>
                            </div>
                        </InputGroup>
                        { problems.length > 0 ?
                            <div className={styles.problems}>
                                { problems.map((problem: any, index: any) =>
                                    <p key={index}>{problem}</p>
                                )}
                            </div>
                        : null }
                    </>
                :
                    <>
                        <InputGroup
                            extraMargin={false}
                            label="Hvor mange kilometer kjører du i året?"
                            tooltip="Hvor mange kilometer kjørelengde vil dekke kunden i året?"
                            //fieldMessage={showErrors ? 'Du må velge måned' : null}
                        >
                            <Dropdown
                                value={props.currentLoanapplication?.insuranceAnnualKilometers || "choose"}
                                onChange={e => {
                                    loanapplication.setInsuranceAnnualKilometers(e.target.value);
                                }}
                            >
                                <option value="choose" disabled>
                                    Velg kjørelengde
                                </option>
                                { insuranceAnnualKilometersOptions?.map((option: any, index: any) =>
                                    <option key={index} value={option.value}>
                                        {option.label}
                                    </option>
                                )}
                            </Dropdown>
                        </InputGroup>
                        &nbsp;
                        <InputGroup
                            extraMargin={false}
                            label="Hva er din bonus?"
                            tooltip="Hva er kundens nåværende forsikringsbonus?"
                            //fieldMessage={showErrors ? 'Du må velge måned' : null}
                        >
                            <Dropdown
                                value={props.currentLoanapplication?.insuranceBonus || "choose"}
                                onChange={e => {
                                    loanapplication.setInsuranceBonus(e.target.value);
                                }}
                            >
                                <option value="choose" disabled>
                                    Velg bonus
                                </option>
                                { insuranceBonusOptions?.map((option: any, index: any) =>
                                    <option key={index} value={option.value}>
                                        {option.label}
                                    </option>
                                )}
                            </Dropdown>
                        </InputGroup>
                        &nbsp;
                        {/* { Number(loanapplication?.insuranceBonus || 0) >= 60 ?
                            <>
                                <InputGroup
                                    extraMargin={false}
                                    label="Hvilket forsikringsselskap har du denne bonusen hos?"
                                    tooltip="Hva er kundens nåværende forsikringsselskap med denne bonusen?"
                                    //fieldMessage={showErrors ? 'Du må velge måned' : null}
                                >
                                    <Dropdown
                                        value={props.currentLoanapplication?.insuranceBonusSource || "choose"}
                                        onChange={e => {
                                            loanapplication.setInsuranceBonusSource(e.target.value);
                                        }}
                                    >
                                        <option value="choose" disabled>
                                            Velg selskap
                                        </option>
                                        { insuranceCompanies.map((option: any, index: any) =>
                                            <option key={index} value={option.value}>
                                                {option.label}
                                            </option>
                                        )}
                                    </Dropdown>
                                </InputGroup>
                                &nbsp;
                                <InputGroup
                                    label="Regnummer på objektet som har denne bonusen"
                                    tooltip="Registreringsnummer på objektet med bonusen"
                                >
                                    {inputProps => (
                                        <Input
                                            type="text"
                                            name="regnumber"
                                            value={props.currentLoanapplication?.insuranceBonusSourceRegnr || ""}
                                            onChange={e => {
                                                loanapplication.setInsuranceBonusSourceRegnr(e.target.value);
                                            }}
                                            {...inputProps}
                                        />
                                    )}
                                </InputGroup>
                                &nbsp;
                            </>
                        : null } */}
                        <RadioButtonInputGroup
                            label="Blir objektet brukt av personer under 23 år?"
                            name="lowage"
                            onChange={e => {
                                loanapplication.setInsuranceLowAge(e.target.value == "true");
                            }}
                            selectedValue={props.currentLoanapplication?.insuranceLowAge != undefined ? props.currentLoanapplication?.insuranceLowAge?.toString() : ""}
                        >
                            {inputProps => (
                                <RadioSwitch
                                    leftLabel="Ja"
                                    leftValue="true"
                                    rightLabel="Nei"
                                    rightValue="false"
                                    {...inputProps}
                                />
                            )}
                        </RadioButtonInputGroup>
                        &nbsp;
                        {/* <InputGroup
                            extraMargin={false}
                            label="Hvilket selskap har du finansiering hos?"
                            tooltip="Hva er kundens finansieringsselskap for objektet?"
                        >
                            <Dropdown
                                value={props.currentLoanapplication?.loanLeasingCompany || "choose"}
                                onChange={e => {
                                    loanapplication.setLoanLeasingCompany(e.target.value);
                                }}
                            >
                                <option value="choose" disabled>
                                    Velg selskap
                                </option>
                                { insuranceMortgageesOptions.map((option: any, index: any) =>
                                    <option key={index} value={option.value}>
                                        {option.label}
                                    </option>
                                )}
                            </Dropdown>
                        </InputGroup> */}
                        &nbsp;
                        <ActionButton
                            disabled={calculating || problems.length > 0}
                            onClick={() => {
                                calculatePrice();
                            }}
                        >{calculating ? "Beregner priser .." : "Beregn priser"}</ActionButton>
                        { problems.length > 0 ?
                            <div className={styles.problems}>
                                { problems.map((problem: any, index: any) =>
                                    <p key={index}>{problem}</p>
                                )}
                            </div>
                        : null }
                        <div className={styles.pricecontainer}>
                            { calculationResult != undefined ? 
                                <>
                                    <label className="ffe-form-label">Velg forsikring</label>
                                    <div className={styles.pricelist}>
                                        { calculationResult?.filter((item: any) => item.navn != undefined)?.map((item: any, index: any) => 
                                            <div
                                                className={styles.pricelistitem + " " + (selectedInsurance != undefined && selectedInsurance.dekningsTypeKode == item.dekningsTypeKode ? styles.active : "")}
                                                key={index}
                                                onClick={() => {
                                                    setSelectedInsurance(item);
                                                    loanapplication.setInsuranceCoverageTypeCode(item.dekningsTypeKode);
                                                    
                                                    if (selectedDeductible != undefined && item.prisEgenandelList?.find((i: any) => i.egenAndelsKode == selectedDeductible) == undefined) {
                                                        setSelectedDeductible(item.prisEgenandelList[0].egenAndelsKode);
                                                        loanapplication.setInsuranceDeductibleCode(item.prisEgenandelList[0].egenAndelsKode);
                                                        toast.info("Egenandel ikke tilgjengelig for valgt forsikring, valgt egenandel er endret");
                                                    }
                                                    if (selectedDeductible == undefined) {
                                                        setSelectedDeductible(item.prisEgenandelList[0].egenAndelsKode);
                                                        loanapplication.setInsuranceDeductibleCode(item.prisEgenandelList[0].egenAndelsKode);
                                                    }
                                                }}
                                            >
                                                <div className={styles.pricelistitemleft}>
                                                    <h3>{item.navn}</h3>
                                                    { selectedDeductible != undefined ?
                                                        item.prisEgenandelList?.find((i: any) => i.egenAndelsKode == selectedDeductible) != undefined ?
                                                            <p>
                                                                <span className={styles.price}>{
                                                                    general.formatPrice(general.getInitialInsurancePrice(calculationResult, item.prisEgenandelList?.find((i: any) => i.egenAndelsKode == selectedDeductible)) ?? "", true, true)
                                                                }</span>kr/mnd inkl. trafikkforsikringsavgift
                                                            </p>
                                                        :
                                                            <p>
                                                                Ingen pris for valgt egenandel
                                                            </p>
                                                    :
                                                        item.prisEgenandelList.length == 1 ?
                                                            <p>
                                                                <span className={styles.price}>{general.formatPrice(general.getInitialInsurancePrice(calculationResult, item.prisEgenandelList[0]))}</span>kr/mnd inkl. trafikkforsikringsavgift
                                                            </p>
                                                        :   
                                                            <p>
                                                                <span className={styles.price}>{general.formatPrice(general.getInitialInsurancePrice(calculationResult, item.prisEgenandelList.find((idx: any ) => idx.prisPerMaaned == Math.min(...item.prisEgenandelList.map((i: any) => i.prisPerMaaned)))))}</span>
                                                                &mdash;&nbsp;&nbsp;
                                                                <span className={styles.price}>{general.formatPrice(general.getInitialInsurancePrice(calculationResult, item.prisEgenandelList.find((idx: any ) => idx.prisPerMaaned == Math.max(...item.prisEgenandelList.map((i: any) => i.prisPerMaaned)))))}</span>
                                                                kr/mnd inkl. trafikkforsikringsavgift
                                                            </p>
                                                    }
                                                </div>
                                                <div className={styles.pricelistitemright}>
                                                    { item.prisEgenandelList?.find((i: any) => i.egenAndelsKode == selectedDeductible) != undefined ?
                                                        <>
                                                            <p>
                                                                <span className={styles.price}>{
                                                                    general.formatPrice(general.getInitialInsurancePrice(calculationResult, item.prisEgenandelList?.find((i: any) => i.egenAndelsKode == selectedDeductible), true), true, true)
                                                                }</span> kr/år
                                                            </p>
                                                            <button
                                                                className="ffe-tooltip__icon"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    if (tooltipOpen == getTooltipText(item.dekningsTypeKode)) {
                                                                        setTooltipOpen(undefined);
                                                                    } else {
                                                                        setTooltipOpen(getTooltipText(item.dekningsTypeKode));
                                                                    }
                                                                }}
                                                            >
                                                                <span>?</span>
                                                            </button>
                                                        </>
                                                    : null }
                                                </div>
                                            </div>
                                        ) }
                                    </div>
                                    { tooltipOpen != undefined ?
                                        <p className={styles.tooltip}>
                                            {tooltipOpen}
                                        </p>
                                    : null }
                                    { selectedInsurance != undefined ?
                                        <>
                                            <InputGroup
                                                extraMargin={false}
                                                label="Egenandel"
                                                tooltip="Hvor stor egenandel ønsker kunden? Egenandelen er det beløpet som kunden må betale selv ved skade."
                                            >
                                                <Dropdown
                                                    value={selectedDeductible}
                                                    onChange={e => {
                                                        setSelectedDeductible(e.target.value);
                                                        loanapplication.setInsuranceDeductibleCode(e.target.value);
                                                    }}
                                                >
                                                    <option value="choose" disabled>
                                                        Velg egenandel
                                                    </option>
                                                    { selectedInsurance.prisEgenandelList?.map((option: any, index: any) =>
                                                        <option key={index} value={option.egenAndelsKode}>
                                                            {option.egenAndel}
                                                        </option>
                                                    )}
                                                </Dropdown>
                                            </InputGroup>
                                            <div className={styles.ekundeSamtykke}>
                                                <hr/>
                                                <h5 className="ffe-h5">Kundens samtykke til elektronisk kommunikasjon</h5>
                                                <p>Ved å samtykke til elektronisk kommunikasjon får ikke kunden forsikringsavtalen i posten, men har den alltid tilgjengelig på nett. Ønsker kunden å trekke tilbake samtykket kan kunden gjøre det på nett eller ta kontakt med SpareBank 1 på telefon eller e-post.</p>
                                                <InputGroup
                                                    className={styles.checkboxinputgroup}
                                                >
                                                    <div className={styles.checkboxcontainer}>
                                                        <Checkbox
                                                            name="ekundeSamtykke"
                                                            inline={false}
                                                            checked={loanapplication.ekundeSamtykke}
                                                            onChange={e => {
                                                                loanapplication.setEkundeSamtykke(e.target.checked);
                                                            }}
                                                        >
                                                            Jeg har informert kunden og kunden har gitt sitt samtykke til elektronisk kommunikasjon
                                                        </Checkbox>
                                                    </div>
                                                </InputGroup>
                                                <hr/>
                                                <h5 className="ffe-h5">Forutsetninger for salg</h5>
                                                <ul>
                                                    <li>Jeg har videreformidlet <a className="ffe-link-text" href="https://dokument.fremtind.no/vilkar/fremtind/pm/mobilitet/Vilkar_Toppkasko_Bil.pdf" rel="noreferrer" target="_blank">vilkårene for forsikringen</a></li>
                                                    <li>Jeg har videreformidlet <a className="ffe-link-text" href="https://dokument.fremtind.no/ipid/IPID_BIL.pdf" rel="noreferrer" target="_blank">produktinformasjon (IPID) for bil (PDF)</a></li>
                                                    <li>Jeg har informert kunden om at prisen forutsetter korrekte opplysninger</li>
                                                    <li>Jeg er informert om at eierskiftet må fullføres innen 90 dager</li>
                                                    <li>Jeg har informert kunden om at denne forsikringen leveres fra 01.01.2019 av Fremtind AS org.nr. 915 651 232 eiet av SpareBank 1 Gruppen og DNB</li>
                                                </ul>
                                                <hr/>
                                                <h5 className="ffe-h5">Per måned</h5>
                                                <p className={styles.insurancePriceLine}><span>{general.getInsurancePricing(calculationResult, selectedDeductible, selectedInsurance).forsikring}</span> {selectedInsurance.navn} + 
                                                <span>{general.getInsurancePricing(calculationResult, selectedDeductible, selectedInsurance).trafikkforsikringsavgift}</span> Trafikkforsikringsavgift +
                                                <span>{general.getInsurancePricing(calculationResult, selectedDeductible, selectedInsurance).panthavergaranti}</span> Panthavergaranti = 
                                                Totalt <span>{general.getInsurancePricing(calculationResult, selectedDeductible, selectedInsurance).total}</span></p>
                                                <p>Kunden vil bli fakturert med en månedlig faktura.</p>
                                            </div>
                                        </>
                                    : null }
                                </>
                            : null }
                        </div>
                    </>
            : null }
        </ContentCard>
    );
}

interface CaseInsuranceCardProps {
    disabled: boolean;
    open: boolean;
    currentLoanapplication: LoanapplicationModel | undefined;
    objectCategorySequence?: number | undefined;
}

export default CaseInsuranceCard;