import React, { useEffect, useState } from "react";
import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";
import { Dealer } from "../../../model/Dealer";
import { toast } from 'react-toastify';

import styles from './DealerSelecter.module.scss';

function DealerSelecter(props: DealerSelecterProps) {
    const axiosHandler = useAxios()
    const axios = axiosHandler.axios;
    const auth = useAuth();

    const [dealers, setDealers] = useState<Array<Dealer>>([]);
    const [open, setOpen] = useState(false);

    function getMe() {
        if (auth.user.userdata == undefined && localStorage.getItem("logindata") != undefined){
            auth.setLoading(true);
            axios.single({
                url: "/api/users/me",
                method: "GET",
                headers: {
                    "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "{}")?.["JWT"])
                }
            }).then((res: any) => {
                if (res.data != undefined) {
                    auth.setUser({
                        ...auth.user,
                        userdata: res.data
                    });
                    setDealers(res.data.Dealers);
                    auth.setLoading(false);
                    localStorage.setItem("logindata", JSON.stringify({
                        ...JSON.parse(localStorage.getItem("logindata") ?? "{}"),
                        "userdata": res.data
                    }));
                }
            }).catch((err: any) => {
                console.log(err);
                auth.setLoading(false);
                toast.error("Innlasting av brukerdata feilet! Klikk her for å prøve igjen.", {
                    position: "top-center",
                    autoClose: false,
                    onClick: () => {
                        getMe();
                    }
                });
            });
        }
        else {
            if (localStorage.getItem("logindata") == undefined) {
                auth.signout().then(() => {
                    window.location.reload();
                });
            }
            setDealers(auth.user?.userdata?.Dealers ?? []);
        }
    }

    useEffect(() => {
        getMe();
        if (localStorage.getItem("selectedDealerSequence") != null) {
            getDealer(Number(localStorage.getItem("selectedDealerSequence")));
        } else {
            let defaultDealer = auth.user?.DefaultDealerSequence;
            getDealer(defaultDealer);
        }
    }, []);

    function getDealer(dealerSequence: number | undefined) {
        if (dealerSequence !== undefined) {
            localStorage.setItem("selectedDealerSequence",dealerSequence.toString());
            auth.setLoading(true);
            axios.single({
                url: "/api/dealers/" + dealerSequence,
                method: "GET",
                headers: {
                    "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
                }
            }).then((res: any) => {
                if (res.data.Sequence !== undefined) {
                    auth.setSelectedDealer(res.data);
                    if (res.data.ColorPrimary !== undefined) {
                        const logoDark = res.data?.CompanyLogo?.find((logo: any) => logo.CompanyLogoType == "Dark")?.Image ?? undefined;
                        const logoLight = res.data?.CompanyLogo?.find((logo: any) => logo.CompanyLogoType == "Light")?.Image ?? undefined;
                        auth.setSettings({
                            whitelabel: {
                                total: res.data.TotalWhiteLabel,
                                logoDark: logoDark ? ("data:image/png;base64," + logoDark) : "",
                                logoLight: logoLight ? ("data:image/png;base64," + logoLight) : "",
                                colorPrimary: res.data.ColorPrimary,
                                colorPrimaryLight: res.data.ColorPrimaryLight,
                                colorSecondary: res.data.ColorSecondary,
                                colorSecondaryLight: res.data.ColorSecondaryLight,
                            }
                        });
                    }
                    if (res.data.DealerGroupAgreementConnection == undefined) {
                        toast.error(`Ingen avtaler funnet for ${res.data.CompanyName}. Hvis feilen vedvarer vennligst ta kontakt med Sparebank1 Finans Østlandet.`, {autoClose:false, position: "top-center"})
                    } 
                } else {
                    toast.error("Innlasting av valgt dealer feilet! Dealer " + dealerSequence + " ble ikke funnet.");
                }
                auth.setLoading(false);
            }).catch((err: any) => {
                console.log(err);
                auth.setLoading(false);
                toast.error("Innlasting av valgt dealer feilet! Vennligst velg dealer på nytt");
            });
        }
    }

    useEffect(() => {
        if(props.closedmenu == true){
            setOpen(false);
        }
    }, [props.closedmenu]);

    return (
        dealers.length < 2 ?
            <div 
                className={styles.navdealerswitcher}
                style={{
                    cursor:"unset"
                }}
            >
                <div className={styles.selectdealer}>
                    <span className="material-symbols-outlined accounticon">account_circle</span>
                        <p>
                            <span>
                                {auth.selectedDealer?.ID == "" ? "Laster inn .." : auth.selectedDealer?.CompanyName}
                            </span>
                        </p>
                </div>
            </div>
        :
            <div className={styles.navdealerswitcher + ' ' + (open ? styles.dropdownopen : '' )}
                onClick={() => {
                    setOpen(!open);
                    props.onClick?.();
                }}
            >
                <div className={styles.selectdealer + ' ' + (props.closedmenu ? styles.closedmenu : '' )}>
                    <span className="material-symbols-outlined accounticon">account_circle</span>
                        <>
                            <p>
                                <span>
                                    {auth.selectedDealer?.ID == "" ? "Laster inn .." : auth.selectedDealer?.CompanyName}.
                                </span>
                            </p>
                            <span className="material-symbols-outlined spinbutton">expand_more</span>
                        </>
                </div>
                <div 
                    className={styles.dropdowncontent}
                    style={{
                        display:open ? "block":"none"
                    }}
                >
                    {dealers.map((item, key) => {
                        return <p
                            className={(auth.selectedDealer?.Sequence == item.Sequence) ? styles.activedealer : ""}
                            key={key}
                            onClick={() => {
                                let dealer = new Dealer(item)
                                getDealer(dealer.Sequence);
                                setOpen(false);
                                props.onChange?.(dealer)
                            }}
                        >
                            {item?.CompanyName ?? item?.Sequence}
                        </p>
                    })}
                </div>
            </div>
    );
}

class DealerSelecterProps{
    onChange?: (dealer: Dealer) => void;
    onClick?: Function;
    closedmenu?: boolean;
}

export default DealerSelecter;