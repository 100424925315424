import React, { ReactNode, useEffect, useRef } from "react";
import Dropdown from "@sb1/ffe-dropdown-react";
import { Checkbox, Input, InputGroup, Label, Tooltip } from "@sb1/ffe-form-react";
import ContentCard from "../../../../Shared/ContentCard/ContentCard";
import { Loanapplication as LoanapplicationModel} from "../../../../../model/Loanapplication";
import { toast } from 'react-toastify';

import styles from './CaseCustomerCard.module.scss';
import InputWithUsability from "../../../../Shared/InputWithUsability/InputWithUsability";
import { Grid, GridCol, GridRow } from "@sb1/ffe-grid-react";
import { useAuth } from "../../../../../Auth";
import { useAxios } from "../../../../../AxiosHandler";
import customerFunctions from "../../../../../functions/customerFunctions";
import { useLoanapplication } from "../../../../../LoanapplicationContext";

function CaseCustomerCard(props: CaseCustomerCardProps) {
    const auth = useAuth();
    const loanapplication = useLoanapplication();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;

    const [displayLabelCorrection, setDisplayLabelCorrection] = React.useState<boolean>(false);
    const prevSocialSecurityNumberRef = useRef<string>('');
    
    function sortAddresses(addresses: Array<string>) {
        // Sort addresses by type from sortingmap
        let sortingmap: any = {
            Besøksadresse: 1,
            Offisiell: 2,
            Postadresse: 3,
            Fakturaadresse: 4,
        };

        if (addresses != undefined) {
            addresses.sort((a: any, b: any) => {
                return sortingmap[a.Type] - sortingmap[b.Type];
            });
    
            return addresses;
        } else {
            return [];
        }
    }

    useEffect(() => {
        if (!props.currentLoanapplication.customerSocialSecurityNumber) {
            prevSocialSecurityNumberRef.current = "";
        }
    }, [props.currentLoanapplication.customerSocialSecurityNumber])

    return(
        <ContentCard disabled={props.disabled} open={props.open} title="Kundeopplysninger" className={styles.casecustomercard}>
            <InputGroup
                label={ !(props.currentLoanapplication?.segment != "Privat") || (props.currentLoanapplication?.customerSocialSecurityNumber?.length == 11 && displayLabelCorrection) ?
                        "Personnummer (11 siffer)"
                    : props.currentLoanapplication?.customerSocialSecurityNumber?.length == 9 && props.currentLoanapplication?.segment != "Privat" && displayLabelCorrection ?
                        "Organisasjonsnummer (9 siffer)"
                    :
                        "Organisasjonsnummer eller Personnummer"
                }
                tooltip={ props.currentLoanapplication?.customerSocialSecurityNumber?.length == 11 && displayLabelCorrection ?
                        "Personnummer på 11 siffer. Resten av feltene vil bli fylt ut automatisk."
                    :
                        "Organisasjonsnummer på 9 siffer eller personnummer på 11 siffer dersom enkeltpersonforetak. Resten av feltene vil bli fylt ut automatisk."
                }
                fieldMessage={
                        props.currentLoanapplication?.customerSocialSecurityNumber?.length == 9 &&
                        props.currentLoanapplication?.customerSector == "8200"
                    ?
                        "Denne kunden er enkeltpersonforetak og krever personnummer"
                    : 
                        props.currentLoanapplication?.customerSocialSecurityNumber?.length != 11 &&
                        props.currentLoanapplication?.customerSocialSecurityNumber?.length != 9 &&
                        props.currentLoanapplication?.customerSocialSecurityNumber != "" && 
                        displayLabelCorrection
                    ?
                        props.currentLoanapplication?.segment != "Privat" ?
                            "Ugyldig organisasjonsnummer eller personnummer"
                        :
                            "Ugyldig personnummer"
                    : null
                }
            >
                {inputProps => (
                    <InputWithUsability
                        type="text"
                        name="socialsecuritynumber"
                        useseparatorformatter
                        placeholder={props.currentLoanapplication?.customerLastName != "" && props.currentLoanapplication?.customerLastName != undefined ? "Søk for å bytte kunde .." : "Søk .."}
                        customformatter={
                            props.currentLoanapplication?.customerSocialSecurityNumber?.length != 9 || !(props.currentLoanapplication?.segment != "Privat") ?
                                (value: number): string | ReactNode => {
                                    if (value != 0) {
                                        return <span>{value.toString().replace(/(\d{6})(\d{5})/, "$1 $2")}</span>;
                                    } else {
                                        return "";
                                    }
                                }
                            :
                                (value: number): string | ReactNode => {
                                    if (value != 0) {
                                        return value.toString().replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
                                    } else {
                                        return "";
                                    }
                                }
                        }
                        value={props.currentLoanapplication?.customerSocialSecurityNumber || ""}
                        onChange={e => {
                            setDisplayLabelCorrection(false);
                            if (props.currentLoanapplication?.coCustomerSocialSecurityNumber != e.target.value.replace(/\s/g, '') || e.target.value.replace(/\s/g, '') == "") {
                                loanapplication.setCustomerSocialSecurityNumber(e.target.value.replace(/\s/g, ''));
                            } else {
                                toast.error("Finansieringsansvarlig kan ikke være samme som samskyldner");
                            }
                        }}
                        onBlur={(e) => {
                            const currentSSN = e.target.value.replace(/\s/g, '');
                            const prevSSN = prevSocialSecurityNumberRef.current;
                            
                            if (currentSSN === prevSSN) { return; }

                            setDisplayLabelCorrection(true);
                            if (e.target.value.replace(/\s/g, '').length == 11) {
                                customerFunctions.searchPerson(toast, axios, auth, e.target.value.replace(/\s/g, '')).then((res: any) => {
                                    loanapplication.setCustomerSequence(res.Sequence);
                                    loanapplication.setCustomerEmail(res.Email);
                                    loanapplication.setCustomerPhone(res.Mobile);
                                    loanapplication.setCustomerFirstName(res.FirstName);
                                    loanapplication.setCustomerLastName(res.LastName);
                                    loanapplication.setCustomerAddresses(sortAddresses(res.Addresses));
                                    loanapplication.setInvoiceAddress(sortAddresses(res.Addresses)[0] as any);
                                    loanapplication.setCustomerSector(res.Sector);
                                    if (res == "") {
                                        toast.error("Person ikke funnet");
                                    }
                                }).catch((err: any) => {
                                    toast.error("Person ikke funnet");
                                    loanapplication.setCustomerSequence(undefined);
                                    loanapplication.setCustomerEmail(undefined);
                                    loanapplication.setCustomerPhone(undefined);
                                    loanapplication.setCustomerFirstName(undefined);
                                    loanapplication.setCustomerLastName(undefined);
                                    loanapplication.setCustomerAddresses([]);
                                    loanapplication.setInvoiceAddress(undefined);
                                    loanapplication.setCustomerSector(undefined);
                                });
                            } else if (e.target.value.replace(/\s/g, '').length == 9 && props.currentLoanapplication?.segment != "Privat") {
                                customerFunctions.searchCompany(toast, axios, auth, e.target.value.replace(/\s/g, '')).then((res: any) => {
                                    loanapplication.setCustomerSequence(res.Sequence);
                                    loanapplication.setCustomerLastName(res.CompanyName);
                                    loanapplication.setCustomerFirstName(undefined);
                                    loanapplication.setCustomerEmail(res.Email);
                                    loanapplication.setCustomerPhone(res.Mobile);
                                    loanapplication.setCustomerAddresses(sortAddresses(res.Addresses));
                                    loanapplication.setInvoiceAddress(sortAddresses(res.Addresses)[0] as any);
                                    loanapplication.setCustomerSector(res.Sector);
                                    if (res == "") {
                                        toast.error("Organisasjon ikke funnet");
                                    }
                                }).catch((err: any) => {
                                    toast.error("Organisasjon ikke funnet");
                                    loanapplication.setCustomerSequence(undefined);
                                    loanapplication.setCustomerEmail(undefined);
                                    loanapplication.setCustomerPhone(undefined);
                                    loanapplication.setCustomerFirstName(undefined);
                                    loanapplication.setCustomerLastName(undefined);
                                    loanapplication.setCustomerAddresses([]);
                                    loanapplication.setInvoiceAddress(undefined);
                                    loanapplication.setCustomerSector(undefined);
                                });
                            } else {
                                if (e.target.value.replace(/\s/g, '').length != 0) {
                                    loanapplication.setCustomerSequence(undefined);
                                    loanapplication.setCustomerEmail("");
                                    loanapplication.setCustomerPhone("");
                                    loanapplication.setCustomerFirstName("");
                                    loanapplication.setCustomerLastName("");
                                    loanapplication.setCustomerAddresses([]);
                                    loanapplication.setInvoiceAddress(undefined);
                                }
                            }
                            prevSocialSecurityNumberRef.current = currentSSN;
                        }
                    }
                        {...inputProps}
                    />
                )}
            </InputGroup>
            &nbsp;
            <Grid className={styles.grid}>
                <GridRow>
                    <GridCol sm="6" style={{
                        padding: "0 8px 16px"
                    }}>
                        <InputGroup
                            label={ props.currentLoanapplication?.customerSocialSecurityNumber?.length == 9 && props.currentLoanapplication?.segment != "Privat" && displayLabelCorrection ? "Kontaktperson" : "Fornavn" }
                            //tooltip="Fornavn på kunden. Inkludert dobbeltnavn."
                            //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                        >
                            {inputProps => (
                                <Input
                                    type="text"
                                    name="firstname"
                                    disabled={true}
                                    value={props.currentLoanapplication?.customerFirstName || ""}
                                    onChange={e => {
                                        loanapplication.setCustomerFirstName(e.target.value);
                                    }}
                                    {...inputProps}
                                />
                            )}
                        </InputGroup>
                    </GridCol>
                    <GridCol sm="6" style={{
                        padding: "0 8px 16px"
                    }}>
                        <InputGroup
                            label={ props.currentLoanapplication?.customerSocialSecurityNumber?.length == 9 && props.currentLoanapplication?.segment != "Privat" && displayLabelCorrection ? "Organisasjon" : "Etternavn" }
                            //tooltip="REPLACE ME PLEASE - Etternavn"
                            //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                        >
                            {inputProps => (
                                <Input
                                    type="text"
                                    name="lastname"
                                    disabled={true}
                                    value={props.currentLoanapplication?.customerLastName || ""}
                                    onChange={e => {
                                        loanapplication.setCustomerLastName(e.target.value);
                                    }}
                                    {...inputProps}
                                />
                            )}
                        </InputGroup>
                    </GridCol>
                </GridRow>
            </Grid>
            <InputGroup
                label={ props.currentLoanapplication?.customerSocialSecurityNumber?.length == 9 && props.currentLoanapplication?.segment != "Privat" && displayLabelCorrection ? "E-post - kontaktperson" : "Kundens e-post" }
                //tooltip="REPLACE ME PLEASE - E-post"
                //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
            >
                {inputProps => (
                    <Input
                        type="text"
                        name="email"
                        value={props.currentLoanapplication?.customerEmail || ""}
                        onChange={e => {
                            loanapplication.setCustomerEmail(e.target.value);

                            props.onCustomerEmailChanged?.(e.target.value);
                        }}
                        {...inputProps}
                    />
                )}
            </InputGroup>
            &nbsp;
            <InputGroup
                label={ props.currentLoanapplication?.customerSocialSecurityNumber?.length == 9 && props.currentLoanapplication?.segment != "Privat" && displayLabelCorrection ? "Telefon - kontaktperson" : "Kundens telefon" }
                //tooltip="REPLACE ME PLEASE - Telefon"
                //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
            >
                {inputProps => (
                    <Input
                        type="text"
                        name="phone"
                        value={props.currentLoanapplication?.customerPhone || ""}
                        onChange={e => {
                            loanapplication.setCustomerPhone(e.target.value);

                            props.onCustomerPhoneChanged?.(e.target.value);
                        }}
                        {...inputProps}
                    />
                )}
            </InputGroup>
            &nbsp;
            {/* <InputGroup
                extraMargin={false}
                label="Fakturaadresse"
                //tooltip="Hvilken adresse skal faktura og eventuell informasjon sendes til?"
                //fieldMessage={showErrors ? 'Du må velge måned' : null}
            >
                <Dropdown
                    disabled={props.currentLoanapplication?.customerAddresses?.length == 1}
                    value={
                        (props.currentLoanapplication?.customerAddresses?.find(
                            (a: any) => a.Sequence == props.currentLoanapplication?.invoiceAddress
                        ) as any)?.Sequence || (props.currentLoanapplication?.customerAddresses?.[0] as any)?.Sequence
                    }
                    onChange={e => {
                        loanapplication.setInvoiceAddress(e.target.value as any);
                    }}
                >
                    {props.currentLoanapplication?.customerAddresses?.map((a: any, index: number) => {
                        return (
                            a.Address1 !== undefined ?
                                <option key={index} value={a.Sequence}>{a.Address1} {a.Address2} {a.Address3}, {a.Zip} {a.City}</option>
                            :
                                a.Sequence !== undefined ?
                                    <option key={index} value={a.Sequence}>Adresse funnet</option>
                                :
                                    <option key={index} value={a.Sequence}>Ingen adresse funnet</option>
                        );
                    })}
                </Dropdown>
            </InputGroup> */}
            &nbsp;
            {/* Only show this if segment is private */}
            { props.currentLoanapplication?.segment == "Privat" && props.currentLoanapplication?.type != "Leasing" ?
                <InputGroup
                    className={styles.checkboxinputgroup}
                    //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                >
                    <div className={styles.checkboxcontainer}>
                        <Checkbox
                            name="coloaner"
                            inline={false}
                            checked={props.currentLoanapplication?.customerCoborrower || false}
                            onChange={e => {
                                loanapplication.setCustomerCoborrower(e.target.checked);
                            }}
                        >
                            Samskyldner
                            <Tooltip>{"Skal finansieringen deles med andre personer enn den som søker om finansiering?"}</Tooltip>
                        </Checkbox>
                    </div>
                </InputGroup>
            : null }
            <div style={{display: props.currentLoanapplication?.customerCoborrower ? "block" : "none"}}>
                <hr/>
                <p style={{
                    fontWeight: "600",
                    fontSize: "18px",
                }}>Samskyldner</p>
                <InputGroup
                    label={ props.currentLoanapplication?.coCustomerSocialSecurityNumber?.length == 11 ?
                            "Personnummer (11 siffer)"
                        : props.currentLoanapplication?.coCustomerSocialSecurityNumber?.length == 9 ?
                            "Organisasjonsnummer (9 siffer)"
                        :
                            "Organisasjonsnummer eller Personnummer"
                    }
                    tooltip={ props.currentLoanapplication?.coCustomerSocialSecurityNumber?.length == 11 ?
                            "Personnummer på 11 siffer. Resten av feltene vil bli fylt ut automatisk."
                        :
                            "Organisasjonsnummer på 9 siffer eller personnummer på 11 siffer dersom enkeltpersonforetak. Resten av feltene vil bli fylt ut automatisk."
                    }
                    fieldMessage={
                        props.currentLoanapplication?.coCustomerSocialSecurityNumber?.length == 9 &&
                        props.currentLoanapplication?.coCustomerSector == "8200"
                    ?
                        "Denne kunden er enkeltpersonforetak og krever personnummer"
                    : null }
                >
                    {inputProps => (
                        <InputWithUsability
                            type="text"
                            name="cosocialsecuritynumber"
                            useseparatorformatter
                            customformatter={
                                (value: number): string => {
                                    if (value != 0) {
                                        return value.toString().replace(/(\d{6})(\d{5})/, "$1 $2");
                                    } else {
                                        return "";
                                    }
                                }
                            }
                            value={props.currentLoanapplication?.coCustomerSocialSecurityNumber || ""}
                            onChange={e => {
                                if (props.currentLoanapplication?.customerSocialSecurityNumber != e.target.value.replace(/\s/g, '') || e.target.value.replace(/\s/g, '') == "") {
                                    loanapplication.setCoCustomerSocialSecurityNumber(e.target.value.replace(/\s/g, ''));
                                } else {
                                    toast.error("Samskyldner kan ikke være samme som finansieringsansvarlig");
                                }
                            }}
                            onBlur={(e) => {
                                if (e.target.value.replace(/\s/g, '').length == 11) {
                                    customerFunctions.searchPerson(toast, axios, auth, e.target.value.replace(/\s/g, '')).then((res: any) => {
                                        loanapplication.setCoCustomerSequence(res.Sequence);
                                        loanapplication.setCoCustomerEmail(res.Email);
                                        loanapplication.setCoCustomerPhone(res.Mobile);
                                        loanapplication.setCoCustomerFirstName(res.FirstName);
                                        loanapplication.setCoCustomerLastName(res.LastName);
                                        loanapplication.setCoCustomerSector(res.Sector);
                                    }).catch((err: any) => {
                                        loanapplication.setCoCustomerSequence(undefined);
                                        loanapplication.setCoCustomerEmail(undefined);
                                        loanapplication.setCoCustomerPhone(undefined);
                                        loanapplication.setCoCustomerFirstName(undefined);
                                        loanapplication.setCoCustomerLastName(undefined);
                                        loanapplication.setCoCustomerSector(undefined);
                                    });
                                } else if (e.target.value.replace(/\s/g, '').length == 9) {
                                    customerFunctions.searchCompany(toast, axios, auth, e.target.value.replace(/\s/g, '')).then((res: any) => {
                                        loanapplication.setCoCustomerSequence(res.Sequence);
                                        loanapplication.setCoCustomerLastName(res.CompanyName);
                                        loanapplication.setCoCustomerFirstName(undefined);
                                        loanapplication.setCoCustomerEmail(res.Email);
                                        loanapplication.setCoCustomerPhone(res.Mobile);
                                        loanapplication.setCoCustomerSector(res.Sector);
                                    }).catch((err: any) => {
                                        loanapplication.setCoCustomerSequence(undefined);
                                        loanapplication.setCoCustomerEmail(undefined);
                                        loanapplication.setCoCustomerPhone(undefined);
                                        loanapplication.setCoCustomerFirstName(undefined);
                                        loanapplication.setCoCustomerLastName(undefined);
                                        loanapplication.setCoCustomerSector(undefined);
                                    });
                                } else {
                                    loanapplication.setCoCustomerSequence(undefined);
                                    loanapplication.setCoCustomerEmail("");
                                    loanapplication.setCoCustomerPhone("");
                                    loanapplication.setCoCustomerFirstName("");
                                    loanapplication.setCoCustomerLastName("");
                                }
                            }}
                            {...inputProps}
                        />
                    )}
                </InputGroup>
                &nbsp;
                <Grid className={styles.grid}>
                    <GridRow>
                        <GridCol sm="6" style={{
                            padding: "0 8px 16px"
                        }}>
                            <InputGroup
                                label={"Fornavn"}
                            >
                                {inputProps => (
                                    <Input
                                        type="text"
                                        name="cofirstname"
                                        disabled={true}
                                        value={props.currentLoanapplication?.coCustomerFirstName || ""}
                                        onChange={e => {
                                            loanapplication.setCoCustomerFirstName(e.target.value);
                                        }}
                                        {...inputProps}
                                    />
                                )}
                            </InputGroup>
                        </GridCol>
                        <GridCol sm="6" style={{
                            padding: "0 8px 16px"
                        }}>
                            <InputGroup
                                label={"Etternavn"}
                            >
                                {inputProps => (
                                    <Input
                                        type="text"
                                        name="colastname"
                                        disabled={true}
                                        value={props.currentLoanapplication?.coCustomerLastName || ""}
                                        onChange={e => {
                                            loanapplication.setCoCustomerLastName(e.target.value);
                                        }}
                                        {...inputProps}
                                    />
                                )}
                            </InputGroup>
                        </GridCol>
                    </GridRow>
                </Grid>
                <InputGroup
                    label={"E-post"}
                >
                    {inputProps => (
                        <Input
                            type="text"
                            name="coemail"
                            value={props.currentLoanapplication?.coCustomerEmail || ""}
                            onChange={e => {
                                loanapplication.setCoCustomerEmail(e.target.value);
                            }}
                            {...inputProps}
                        />
                    )}
                </InputGroup>
                &nbsp;
                <InputGroup
                    label={"Telefon"}
                >
                    {inputProps => (
                        <Input
                            type="text"
                            name="phone"
                            value={props.currentLoanapplication?.coCustomerPhone || ""}
                            onChange={e => {
                                loanapplication.setCoCustomerPhone(e.target.value);
                            }}
                            {...inputProps}
                        />
                    )}
                </InputGroup>
            </div>
        </ContentCard>
    );
}

interface CaseCustomerCardProps {
    disabled: boolean;
    open: boolean;
    currentLoanapplication: LoanapplicationModel;
    onCustomerEmailChanged?: (email: string) => void;
    onCustomerPhoneChanged?: (phone: string) => void;
}

export default CaseCustomerCard;